import { message } from "antd";
import customParseFormat from "dayjs/plugin/customParseFormat";
import dayjs from "dayjs";
import { getNumberFromString } from "../../../utils/NormalizeData";
import { getRecordByKeyValue } from "../../../db/dexie-db/functions";
import { getServer } from "../../../utils/Environment";
import { TABLES } from "../../../db/constants/tables";
dayjs.extend(customParseFormat);

const server = getServer();

const url = server + "/server/server.php";

const getDataFromServer = async (username, action, useFtp) => {
  try {
    const result = await fetch(url, {
      method: "POST",
      body: JSON.stringify({ username, action, useFtp }),
    });
    const res = await result.json();

    return res;
  } catch (error) {
    return false;
  }
};

const getProductsFromXmlElement = async (childrens) => {
  return await Promise.all(
    childrens.map(async (element, ind) => {
      if (!element.guid) return;
      const product = await getRecordByKeyValue(TABLES.PRODUCTS, {
        guid: element.guid,
      });

      const timeAdded = +dayjs().valueOf().toString() + ind;
      if (element?.stockQty && product) product.stockQty = element?.stockQty;
      return {
        ...product,
        qty: getNumberFromString(element.qty),

        price: getNumberFromString(element.price),
        timeAdded,
        index: ind + 1,
      };
    })
  );
};

const getProductsFromXmlElementForVerification = async (childrens) => {
  return await Promise.all(
    childrens.map(async (element) => {
      const product = await getRecordByKeyValue(TABLES.PRODUCTS, {
        guid: element.guid,
      });
      if (!product) {
        message.error("Помилка отримання товару з документа");
        console.log("Помилка отримання товару з документа", product);
      }
      return {
        ...product,
        qtyFromSource: getNumberFromString(element.qty),
        price: getNumberFromString(element.price),
      };
    })
  );
};

export const getFormattedDocs = async (documents) => {
  let badProductsExists = false;
  const res = await Promise.all(
    documents.map(async (doc) => {
      const products = await getProductsFromXmlElement(doc.products);
      const badProducts = products.filter(
        (product) => product.guid === undefined
      );

      if (badProducts.length) {
        badProductsExists = true;
        return false;
      }
      const id = getNumberFromString(doc.id);
      if (!Number(id)) {
        message.error(
          `Помилка завантаження документа з id: ${doc.id}. Id документа має бути числом!`
        );
        return false;
      }
      const created = dayjs(doc.dateTime, "DD.MM.YYYY HH:mm:ss");
      const updated = dayjs(doc.dateTime, "DD.MM.YYYY HH:mm:ss");
      const sum = getNumberFromString(doc.sum);

      return {
        ...doc,
        imported: "true",
        exported: "false",
        sum,
        products,
        id,
        created,
        updated,
        date: dayjs(created.$d).format("DD.MM.YYYY"),
      };
    })
  );

  if (badProductsExists)
    message.error(
      "Не всі документи завантажено, частину товарів не знайдено у вигрузці!"
    );
  return res;
};

export const getFormattedDocsForVerification = (documents) => {
  return Promise.all(
    documents.map(async (doc) => {
      const products = await getProductsFromXmlElementForVerification(
        doc.products
      );
      const id = getNumberFromString(doc.id);
      const created = dayjs(doc.dateTime, "DD.MM.YYYY HH:mm:ss");
      const updated = dayjs(doc.dateTime, "DD.MM.YYYY HH:mm:ss");
      const sum = getNumberFromString(doc.sum);

      return {
        ...doc,
        exported: "false",
        verified: "false",
        sum,
        products,
        id,
        created,
        updated,
        date: dayjs(created.$d).format("DD.MM.YYYY"),
      };
    })
  );
};

export async function getDataFromXml(username, useFtp) {
  const products = await getDataFromServer(username, "importProducts", useFtp);
  const categories = await getDataFromServer(
    username,
    "importCategories",
    useFtp
  );
  const suppliers = await getDataFromServer(
    username,
    "importSuppliers",
    useFtp
  );
  const storages = await getDataFromServer(username, "importStorages", useFtp);
  const documents = await getDataFromServer(
    username,
    "importDocuments",
    useFtp
  );
  const documentsForVerification = await getDataFromServer(
    username,
    "importDocumentsForVerification",
    useFtp
  );

  return {
    documents,
    products,
    categories,
    storages,
    suppliers,
    documentsForVerification,
  };
}
